import Logo from "./UI/Logo";
import BannerSection from "./UI/BannerSection";
import RecruitmentSection from "./UI/RecruitmentVideoSection";
import ContentBoxes from './UI/ContentBoxes.js';
// import Clients from "./UI/Clients";
import GetInTouchWithUs from "./UI/GetInTouchWithUs";
// import ContactUs from './UI/ContactUs';
import { Fragment } from "react";
import Footer from "./Footer";
export default function App() {
  return (
    <Fragment>
      <header className="header">
        <div className="header-container container mx-auto flex items-center justify-center">
          {/* <div className="mx-auto flex-row items-center justify-center">
            <a className="mr-10 text-center"  href="">Products</a>
            <a className="mr-10 text-center"   href="">Inspiration</a>
          </div> */}
          <div className="sm:pr-10 sm:border-r-[2px] border-current sm:mr-10">
            <Logo className="mx-auto  text-center" />
          </div>
          <div className="hidden sm:block">
            <a href="tel:+4733378901" className="mr-10 call-button">CALL</a>
            <a href="mailto:webmaster@example.com" className="call-button">EMAIL US</a>
          </div>
        </div>
      </header>
      <section className="container mx-auto">
        <BannerSection />
      </section>
      <section>
        <ContentBoxes />
      </section>
      <section>
        <RecruitmentSection />
      </section>
      <section>
        <GetInTouchWithUs />
      </section>
      {/* <section>
        <Clients />
      </section> */}
      {/* <section>
        <ContactUs />
      </section> */}
      <section>
        <Footer/>
      </section>
    </Fragment>
  )
}