import React from "react";
import Banner_image from "../Assets/Banner_image.jpeg";

const BannerSection = () => {
  return (
    <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-10 mx:gap-20 md:pt-16 md:pb-32">
            <div className=" flex items-center justify-end">
                <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl w-auto text-right">Get a career where you can flourish. Take action right away.</h1>
                <img className="rounded-lg sm:hidden p-10" src={Banner_image} alt="" />
            </div>
            <div className=" flex items-center justify-center hidden sm:block">
                <img className="rounded-lg" src={Banner_image} alt="" />
            </div>
        </div>
        <div className="grid grid-cols-1 sm:hidden gap-5 sm:gap-10 mx:gap-20 md:pt-16 md:pb-32">
            <div className=" flex items-center justify-end">
                <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-7xl w-auto text-center p-10">Get a career where you can flourish. Take action right away.</h1>
            </div>
        </div>
    </div>
  );
};

export default BannerSection;
