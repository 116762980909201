import React from "react";

const Footer = () => {
    return (
        <div className="text-center pt-8 pb-8">
            <h4 className="text-black text-xl">&#169; SKILLER HUNT | All Rights Reserved.</h4>
        </div>
    )
}

export default Footer;