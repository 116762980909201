import React from "react";
import './GetInTouchWithUs.css';
const GetInTouchWithUs = () => {
    return (
        <div className="2xl:container 2xl:pl-16 mx-auto getintouch pb-32 pt-32 bg-neutral-800">
            <div className="container mx-auto">
                <h1 className="text-4xl text-white font-bold pb-8">Get in Touch with Us.</h1>
                <input className="inputBox" placeholder="Type something ..." type="email" name="" id="" />
                <button className="input-button">SEND</button>
            </div>
            <div>
            <div className="container mx-auto pt-16">
                <h1 className="text-4xl text-white font-bold pb-4">Contact Us.</h1>
                <h3 className="text-white text-2xl">SkillerHunt, Raja Rajeswari Conoly, Kondapur, Hyderabad.</h3>
            </div>
            <div className="container mx-auto pt-16">
                <h1 className="text-4xl text-white font-bold pb-4">Phone Number.</h1>
                <h3 className="text-white text-2xl">+91 8639773484</h3>
            </div>
            <div className="container mx-auto pt-16">
                <h1 className="text-4xl text-white font-bold pb-4">Email Us.</h1>
                <h3 className="text-white text-2xl">contact@skillerhunt.com</h3>
            </div>
            </div>
        </div>
    )
}
export default GetInTouchWithUs;