import React from "react";
import "./RecruitmentVideoSection.css";

const RecruitmentVideoSection = () => {
  return (
    <div className="bg-white">
      <div className="container mx-auto pt-32 pb-32 text-center">
        <div className="flex align-middle justify-center recruitment-video">
          <iframe
            width="800"
            height="500"
            src="https://www.youtube.com/embed/A2HFusWQIeE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <h2 className="pt-16 text-black pl-32 pr-32 text-3xl pl">
          Human resource management, HRM, is the department of a business
          organization that looks after the hiring, management and firing of
          staff. HRM focuses on the function of people within the business,
          ensuring best work practices are in place at all times.
        </h2>
      </div>
    </div>
  );
};

export default RecruitmentVideoSection;
