import React from "react";
import "./ContentBoxes.css";

const ContentBoxes = () => {
  return (
    <div className="flex flex-wrap 2xl:container mx-auto">
      <div className="flex flex-col content-box w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 bg-neutral-800">
        <h4>Training and development</h4>
        <p className="text-slate-300 flex-grow">
          Training and development initiatives are educational activities within
          an organization that are designed to improve the job performance of an
          individual or group. These programs typically involve advancing a
          worker's knowledge and skill sets and instilling greater motivation to
          enhance job performance.
        </p>
        {/* <button className="content-box-button">Load more</button> */}
      </div>
      <div className="flex flex-col content-box w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 bg-neutral-700">
        <h4>Onboarding</h4>
        <p className="text-slate-300 flex-grow">
          Taking the new hire around the office and introducing him/her to other
          employees. Taking the new hire out to lunch on their first day.
          Checking in with the new hire regularly.
        </p>
        {/* <button className=" content-box-button">Load more</button> */}
      </div>
      <div className="flex flex-col content-box w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 bg-neutral-800">
        <h4>Placement</h4>
        <p className="text-slate-300 flex-grow">
          Placement refers to the process of connecting the selected person and
          the employer in order to establish an ongoing employment relationship.
          In this step the employee is given the activities he/she needs to
          perform and is told about his/her duties.
        </p>
        {/* <button className=" content-box-button">Load more</button> */}
      </div>
      <div className="flex flex-col content-box w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 bg-neutral-700">
        <h4>Recruitment and selection</h4>
        <p className="text-slate-300 flex-grow">
          Recruitment involves actively seeking out and advertising to potential
          candidates and obtaining their interest in the position. Selection
          refers to the process of determining the best candidate from the pool
          of applicants.
        </p>
        {/* <button className="content-box-button">Load more</button> */}
      </div>
    </div>
  );
};

export default ContentBoxes;
